//BURGER MENU
jQuery(function ($) {
	$("i.fas.fa-search").click(function() {
		$("input.search-field").slideToggle(200);
	});
	
	$(".nav-butt").click(function() {
		$(".off-top-menu-container").addClass('active').fadeIn(200);

		setTimeout(function(){
			$(".off-top-menu").addClass('active');
		}, 100);
		
	});

	$(".close").click(function() {
		$(".off-top-menu").removeClass('active');
		
		setTimeout(function(){
			$(".off-top-menu-container").removeClass('active').fadeOut(200);
		}, 200);
	});
	
	$('.mobile li > a').click(function() {
	    $(this).siblings('.sub-menu').slideToggle(200);
	});

});

